import { ChakraTheme, extendTheme } from "@chakra-ui/react";
const theme: Partial<ChakraTheme> = {
  radii: {
    base: "1px",
    md: "2px",
    lg: "2px",
  },
  colors: {
    brand: {
      "50": "#E8F7FC",
      "100": "#BFE9F7",
      "200": "#96DBF2",
      "300": "#6ECDED",
      "400": "#45C0E8",
      "500": "#1CB2E3",
      "600": "#168EB6",
      "700": "#116B88",
      "800": "#0B475B",
      "900": "#06242D",
    },
  },
};

export default extendTheme(theme);
